import auth from "./auth";
import { createRouter, createWebHashHistory } from "vue-router";

import Home from "./views/home-page";
import Profile from "./views/profile-page";
import Tasks from "./views/tasks-page";
import defaultLayout from "./layouts/side-nav-outer-toolbar";
import simpleLayout from "./layouts/single-card";
import cetakLayout from "./layouts/cetak-layout";

function loadView(view) {
  return () => import(/* webpackChunkName: "login" */ `./views/${view}.vue`)
}

function createRoute(name, path, key, layout = defaultLayout) {
  return {
    path,
    name,
    meta: {
      requiresAuth: true,
      layout,
      key
    },
    component: loadView(name + "-page")
  }
}

const router = new createRouter({
  routes: [
    {
      path: "/home",
      name: "home",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Home
    },
    // master
    createRoute('pengguna', '/pengguna', 'pengguna'),
    createRoute('jabatan', '/jabatan', 'jabatan'),
    createRoute('setting-poin', '/setting/poin', 'setting_poin'),
    createRoute('setting-akun', '/setting/akun', 'setting_akun'),
    createRoute('setting-no-dokumen', '/setting/no_dokumen', 'setting_no_dokumen'),
    createRoute('setting-aplikasi', '/setting/aplikasi', 'setting_aplikasi'),
    createRoute('setting-android', '/setting/android', 'setting_android'),
    createRoute('giro', '/giro', 'giro'),
    createRoute('barang', '/barang', 'barang'),
    createRoute('brand', '/brand', 'brand'),
    createRoute('gudang', '/gudang', 'gudang'),
    createRoute('departemen', '/departemen', 'departemen'),
    createRoute('kategori-barang', '/kategori_barang', 'kategori_barang'),
    createRoute('kategori-property', '/kategori_property', 'kategori_property'),
    createRoute('tarif-billing', '/tarif_billing', 'tarif_billing'),
    createRoute('kategori-akun', '/kategori_akun', 'kategori_akun'),
    createRoute('sales', '/sales', 'sales'),
    createRoute('area-sales', '/area_sales', 'area_sales'),
    createRoute('ekspedisi', '/ekspedisi', 'ekspedisi'),
    createRoute('edc', '/edc', 'edc'),
    createRoute('kartu-bank', '/kartu_bank', 'kartu_bank'),
    createRoute('bank', '/bank', 'bank'),
    createRoute('supplier', '/supplier', 'supplier'),
    createRoute('vendor', '/vendor', 'vendor'),
    createRoute('pelanggan', '/pelanggan', 'pelanggan'),
    createRoute('kategori_pelanggan', '/kategori_pelanggan', 'kategori_pelanggan'),
    createRoute('akun', '/akun', 'akun'),
    createRoute('karyawan', '/karyawan', 'karyawan'),
    createRoute('petugas', '/petugas', 'petugas'),
    createRoute('property', '/property', 'property'),
    createRoute('lokasi', '/lokasi', 'lokasi'),
    createRoute('addon', '/addon', 'addon'),
    createRoute('ongkir', '/ongkir', 'ongkir'),

    // gaji
    createRoute('komponen_gaji', '/komponen_gaji', 'komponen_gaji'),
    createRoute('slip_gaji', '/slip_gaji', 'slip_gaji'),
    createRoute('slip_gaji_cetak', '/slip_gaji_cetak/:id', 'slip_gaji_cetak', cetakLayout),

    // poin
    createRoute('poin', '/poin', 'poin'),
    createRoute('poin_pakai', '/poin_pakai', 'poin_pakai'),

    // transaksi
    createRoute('purchase-order', '/purchase_order', 'purchase_order'),
    createRoute('purchase-order-cetak', '/purchase_order_cetak/:id', 'purchase_order', cetakLayout),
    createRoute('terima-barang', '/terima_barang', 'terima_barang'),
    createRoute('terima-barang-cetak', '/terima_barang_cetak/:id', 'terima_barang', cetakLayout),
    createRoute('beli', '/beli', 'beli'),
    createRoute('sales-order', '/sales_order', 'sales_order'),
    createRoute('sales-order-cetak', '/sales_order_cetak/:id', 'sales_order', cetakLayout),
    createRoute('jual', '/jual', 'jual'),
    createRoute('jual_proforma', '/jual_proforma', 'jual_proforma'),
    createRoute('jual-cetak', '/jual_cetak/:id', 'jual', cetakLayout),
    createRoute('delivery-order', '/delivery_order', 'delivery_order'),
    createRoute('delivery-order-cetak', '/delivery_order/:id', 'delivery_order', cetakLayout),
    createRoute('pakai-barang', '/pakai_barang', 'pakai_barang'),
    createRoute('pakai-barang-cetak', '/pakai_barang/:id', 'pakai_barang', cetakLayout),
    createRoute('titip-barang', '/titip_barang', 'titip_barang'),
    createRoute('mutasi-ag', '/mutasi_ag', 'mutasi_ag'),
    createRoute('mutasi-ag-cetak', '/mutasi_ag/:id', 'mutasi_ag', cetakLayout),
    createRoute('konversi-barang', '/konversi_barang', 'konversi_barang'),
    createRoute('stok-opname', '/stok_opname', 'stok_opname'),
    createRoute('stok-opname-cetak', '/stok_opname_cetak/:id', 'stok_opname', cetakLayout),
    createRoute('piutang', '/piutang', 'piutang'),
    createRoute('piutang-cetak', '/piutang_cetak/:id', 'piutang', cetakLayout),
    createRoute('hutang', '/hutang', 'hutang'),
    createRoute('hutang_biaya', '/hutang_biaya', 'hutang_biaya'),
    createRoute('hutang-cetak', '/hutang_cetak/:id', 'hutang', cetakLayout),
    createRoute('bkk', '/bkk', 'bkk'),
    createRoute('bkk-cetak', '/bkk_cetak/:id', 'bkk', cetakLayout),
    createRoute('bkm', '/bkm', 'bkm'),
    createRoute('bkm-cetak', '/bkm_cetak/:id', 'bkm', cetakLayout),
    createRoute('bayar-hutang', '/bayar_hutang', 'bayar_hutang'),
    createRoute('bayar-piutang', '/bayar_piutang', 'bayar_piutang'),
    createRoute('fix-hpp', '/fix_hpp', 'hitung_hpp'),
    createRoute('fix_hpp_task', '/fix_hpp_task', 'fix_hpp_task'),
    createRoute('hpp_adjustment', '/hpp_adjustment', 'hpp_adjustment'),
    createRoute('tarif-pajak', '/tarif_pajak', 'tarif_pajak'),
    createRoute('kartu-stok', '/rep/kartu_stok', 'kartu_stok'),
    createRoute('ledger', '/rep/ledger', 'rep_ledger'),

    createRoute('hutang_karyawan', '/hutang_karyawan', 'hutang_karyawan'),
    createRoute('bayar_hutang_karyawan', '/bayar_hutang_karyawan', 'bayar_hutang_karyawan'),
    createRoute('jurnal', '/jurnal', 'jurnal'),
    createRoute('rekap-pelanggan', '/rekap_pelanggan', 'rekap_pelanggan'),
    createRoute('kartu-piutang', '/rep/kartu_piutang', 'rep_kartu_piutang'),
    createRoute('kartu-hutang', '/rep/kartu_hutang', 'rep_kartu_hutang'),
    createRoute('laba-kotor', '/rep/laba_kotor', 'rep_laba_kotor'),
    createRoute('laba-kotor-lunas', '/rep/laba_kotor_lunas', 'rep_laba_kotor_lunas'),
    createRoute('rep_purchase_order', '/rep/purchase_order', 'rep_purchase_order'),
    createRoute('rep-jual', '/rep/jual', 'rep_jual'),
    createRoute('rep-jual-proforma', '/rep/jual_proforma', 'rep_jual_proforma'),
    createRoute('rep-beli', '/rep/beli', 'rep_beli'),
    createRoute('rep-terima-barang', '/rep/terima_barang', 'rep_terima_barang'),
    createRoute('rep-delivery-order', '/rep/delivery_order', 'rep_delivery_order'),
    createRoute('rep-daftar-jurnal', '/rep/daftar_jurnal', 'rep_daftar_jurnal'),
    createRoute('kelompok-aset', '/kelompok_aset', 'kelompok_aset'),
    createRoute('aset', '/aset', 'aset'),
    createRoute('penyusutan', '/penyusutan', 'penyusutan'),
    createRoute('rep-pakai-barang', '/rep/pakai_barang', 'rep_pakai_barang'),
    createRoute('rep-mutasi-ag', '/rep/mutasi_ag', 'rep_mutasi_ag'),
    createRoute('rep-titip-barang', '/rep/titip_barang', 'rep_titip_barang'),
    createRoute('pricelist', '/pricelist', 'pricelist'),
    createRoute('rep-daftar-hpp', '/rep/daftar_hpp', 'rep_daftar_hpp'),
    createRoute('rep-kartu-stok-hpp', '/rep/kartu_stok_hpp', 'rep_kartu_stok_hpp'),
    createRoute('rep-stok', '/rep/stok', 'rep_stok'),
    createRoute('rep-stok-realtime', '/rep/stok_realtime', 'rep_stok_realtime'),
    createRoute('rep-rekap-jual', '/rep/rekap_jual', 'rep_rekap_jual'),
    createRoute('rep-rekap-kasir', '/rep/rekap_kasir', 'rep_rekap_kasir'),
    createRoute('rep-rekap-gudang', '/rep/rekap_gudang', 'rep_rekap_gudang'),
    createRoute('rep-rekap-jual-barang', '/rep/rekap_jual_barang', 'rep_rekap_jual_barang'),
    createRoute('rep-rekap-jual-proforma', '/rep/rekap_jual_proforma', 'rep_rekap_jual_proforma'),
    createRoute('rep-rekap-piutang', '/rep/rekap_piutang', 'rep_rekap_piutang'),
    createRoute('rep-rekap-hutang', '/rep/rekap_hutang', 'rep_rekap_hutang'),
    createRoute('rep_kegiatan', '/rep/kegiatan', 'rep_kegiatan'),
    createRoute('rep_aktivitas', '/rep/aktivitas', 'rep_aktivitas'),
    createRoute('biaya', '/biaya', 'biaya'),
    createRoute('titip-transfer', '/titip_transfer', 'titip_transfer'),
    createRoute('tarik-tunai', '/tarik_tunai', 'tarik_tunai'),
    createRoute('neraca-saldo', '/rep/neraca_saldo', 'neraca_saldo'),
    createRoute('neraca', '/rep/neraca', 'neraca'),
    createRoute('laba-bersih', '/rep/laba_bersih', 'laba_bersih'),
    createRoute('servis', '/servis', 'servis'),
    createRoute('rep_titip_transfer_tarik_tunai', '/rep/titip_transfer_tarif_tunai', 'rep_titip_transfer_tarik_tunai'),
    createRoute('pindah_kas', '/pindah_kas', 'pindah_kas'),
    createRoute('rekon_bank', '/rekon_bank', 'rekon_bank'),
    createRoute('servis_bayar', '/servis_bayar', 'servis_bayar'),
    createRoute('rep_bayar_piutang', '/rep/bayar_piutang', 'rep_bayar_piutang'),
    createRoute('rep_bayar_hutang', '/rep/bayar_hutang', 'rep_bayar_hutang'),
    createRoute('promo', '/promo', 'promo'),
    // peralatan
    createRoute('fix_data', '/fix_data', 'fix_data'),
    createRoute('schedule', '/schedule', 'schedule'),
    createRoute('trusted_device', '/trusted_device', 'trusted_device'),

    // other
    createRoute('garansi', '/garansi', 'garansi'),
    createRoute('resep', '/resep', 'resep'),

    // negara
    createRoute('lawan_transaksi', '/lawan_transaksi', 'lawan_transaksi'),
    createRoute('barang_efaktur', '/barang_efaktur', 'barang_efaktur'),
    createRoute('faktur_masukan', '/faktur_masukan', 'faktur_masukan'),
    createRoute('faktur_keluaran', '/faktur_keluaran', 'faktur_keluaran'),

    // project
    createRoute('spk', '/spk', 'spk'),
    createRoute('survey', '/survey', 'survey'),
    createRoute('rab', '/rab', 'rab'),
    createRoute('penawaran', '/penawaran', 'penawaran'),
    createRoute('surat_pesanan', '/surat_pesanan', 'surat_pesanan'),
    createRoute('spk_barang', '/spk_barang', 'spk_barang'),
    createRoute('spk_biaya', '/spk_biaya', 'spk_biaya'),
    createRoute('bayar_spk_biaya', '/bayar_spk_biaya', 'bayar_spk_biaya'),
    createRoute('pengerjaan', '/pengerjaan', 'pengerjaan'),
    createRoute('serah_terima', '/serah_terima', 'serah_terima'),
    createRoute('invoice_spk', '/invoice_spk', 'invoice_spk'),
    createRoute('bayar_spk', '/bayar_spk', 'bayar_spk'),
    createRoute('rep_aktivitas_spk', '/rep/aktivitas_spk', 'rep_aktivitas_spk'),
    createRoute('spk_selesai', '/spk_selesai', 'spk_selesai'),
    createRoute('rep_barang_biaya_spk', '/rep_barang_biaya_spk', 'rep_barang_biaya_spk'),
    {
      path: "/profile",
      name: "profile",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Profile
    },
    {
      path: "/tasks",
      name: "tasks",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Tasks
    },
    {
      path: "/login-form",
      name: "login-form",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign In"
      },
      component: loadView("login-form")
    },
    {
      path: "/no_akses",
      name: "no_akses",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign In"
      },
      component: loadView("no-akses-page")
    },
    {
      path: "/reset-password",
      name: "reset-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Reset Password",
        description: "Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
      },
      component: loadView("reset-password-form")
    },
    {
      path: "/create-account",
      name: "create-account",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign Up"
      },
      component: loadView("create-account-form"),
    },
    {
      path: "/change-password/:recoveryCode",
      name: "change-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Change Password"
      },
      component: loadView("change-password-form")
    },
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: "/recovery",
      redirect: "/home"
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/home"
    }
  ],
  history: createWebHashHistory()
});

router.beforeEach((to, from, next) => {

  if (to.name === "login-form" && auth.loggedIn()) {
    return next({ name: "home" });
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!auth.loggedIn()) {
      next({
        name: "login-form",
        query: { redirect: to.fullPath }
      });
    } else {
      if (to.fullPath == '/home') {
        return next()
      }
      if (auth.boleh(to.meta.key + ':lihat')) {
        next()
      } else {
        next({
          name: 'no_akses'
        })
      }
    }
  } else {
    next();
  }
});

export default router;
