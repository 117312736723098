<template>
  <div>
    <h2 class="content-block">Home</h2>
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <p>
          this website is for authorized credential only.
        </p>
      </div>
    </div>
  </div>
</template>
